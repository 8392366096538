import React, { useState } from "react";
import { uploadImage } from "../services/firebaseService";

const ImageUploader = ({ onImageUpload }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewUrl(URL.createObjectURL(file));
      setIsUploading(true);
      try {
        const imageUrl = await uploadImage(file);
        onImageUpload(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <label className="w-64 flex flex-col items-center px-4 py-6 bg-white dark:bg-gray-700 text-blue-500 dark:text-blue-300 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 dark:border-blue-300 cursor-pointer hover:bg-blue-500 hover:text-white dark:hover:bg-blue-600">
        <svg
          className="w-8 h-8"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Select an image</span>
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept="image/*"
        />
      </label>
      {isUploading && (
        <p className="mt-4 text-gray-700 dark:text-gray-300">Uploading...</p>
      )}
      {previewUrl && (
        <div className="mt-4">
          <img
            src={previewUrl}
            alt="Preview"
            className="max-w-xs max-h-48 object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
