import { useState, useEffect, useContext } from "react";
import { getStudentRotations } from "../../services/firebaseService";
import RotationsTable from "./RotationsTable";
import Calendar from "../Calendar/Calendar";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";

const StudentRotations = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [rotations, setRotations] = useState([]);
  const [filteredRotations, setFilteredRotations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rotationsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchStudentRotations = async () => {
      setLoading(true);
      try {
        const fetchedRotations = await getStudentRotations(user.id);
        setRotations(fetchedRotations);
        const colors = [
          "rgba(0, 128, 128, 0.5)",
          "rgba(0, 0, 255, 0.5)",
          "rgba(0, 128, 0, 0.5)",
          "rgba(128, 0, 128, 0.5)",
          "rgba(255, 165, 0, 0.5)",
          "rgba(255, 0, 0, 0.5)",
        ];
        setEvents(
          fetchedRotations.map((rotation, index) => {
            const randomColor =
              colors[Math.floor(Math.random() * colors.length)];
            return {
              id: rotation.opps.id,
              name: rotation.opps.name,
              startDate: rotation.opps.startDate.split("T")[0],
              endDate: rotation.opps.endDate.split("T")[0],
              time: rotation.opps.startDate.split("T")[1],
              color: randomColor,
            };
          })
        );
        setFilteredRotations(fetchedRotations);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (user.id) {
      fetchStudentRotations();
    }
  }, [user.id]);

  const handleSearch = (searchTerm) => {
    const filtered = rotations.filter((rotation) =>
      rotation.opps.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRotations(filtered);
  };

  // Get current rotations
  const indexOfLastRotation = currentPage * rotationsPerPage;
  const indexOfFirstRotation = indexOfLastRotation - rotationsPerPage;
  const currentRotations = rotations.slice(
    indexOfFirstRotation,
    indexOfLastRotation
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
        </div>
      )}
      {error && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Error
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => setError(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="container mx-auto p-4">
        <Calendar events={events} />
        <h1 className="text-2xl font-bold mb-4">Student Rotations</h1>
        <input
          type="text"
          placeholder="Find rotations by name"
          className="w-full p-2 mb-4 border border-gray-300 rounded bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <RotationsTable
          filteredRotations={filteredRotations}
          navigate={navigate}
          baseId={user.id}
        />
      </div>
    </>
  );
};

export default StudentRotations;
