import React, { useState, useEffect } from "react";
import { getStudentUsers } from "../../services/firebaseService";
import { useNavigate } from "react-router-dom";

const Students = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      const studentData = await getStudentUsers();
      setStudents(studentData);
    };

    fetchStudents();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Students List</h1>
      <table className="min-w-full bg-white dark:bg-gray-800">
        <thead>
          <tr>
            <th className="text-left py-2 px-4 border-b">Name</th>
            {/* <th className="py-2 px-4 border-b">Email</th> */}
            <th className="text-left py-2 px-4 border-b">Role</th>
            <th className="text-left py-2 px-4 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.id}>
              <td className="py-2 px-4 border-b">{student.name}</td>
              {/* <td className="py-2 px-4 border-b">{student.email}</td> */}
              <td className="py-2 px-4 border-b">{student.role}</td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => navigate(`/student/${student.id}/search`)}
                  className="bg-blue-500 text-white px-2 py-1 rounded-md"
                >
                  Use
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Students;
