const Dashboard = ({ user }) => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">Dashboard</h1>
      <p>Welcome, {user.displayName}</p>
      {/* Add summary of rotations, notifications, and quick links here */}
    </div>
  );
};

export default Dashboard;
