import { useState } from "react";

const defaultGetTypeBadgeColor = (type) => {
  switch (type) {
    case "Core":
      return "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300";
    case "Elective":
      return "bg-purple-100 text-purple-500 dark:bg-purple-900 dark:text-purple-300";
    default:
      return "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300";
  }
};

const defaultGetStatusBadgeColor = (status) => {
  switch (status) {
    case 1:
      return "bg-yellow-100 text-yellow-500 dark:bg-yellow-900 dark:text-yellow";
    case 2:
    case 3:
    case 4:
      return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-500";
    case 5:
      return "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300";
    case 6:
      return "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300";
    default:
      return "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300";
  }
};

const RotationsTable = ({
  filteredRotations = [],
  navigate,
  baseId,
  currentPage = 1,
  rotationsPerPage = 10,
  getTypeBadgeColor = defaultGetTypeBadgeColor,
  getStatusBadgeColor = defaultGetStatusBadgeColor,
}) => {
  const [page, setPage] = useState(currentPage);

  const indexOfLastRotation = page * rotationsPerPage;
  const indexOfFirstRotation = indexOfLastRotation - rotationsPerPage;
  const currentRotations = filteredRotations.slice(
    indexOfFirstRotation,
    indexOfLastRotation
  );
  // console.log(currentRotations);

  const handlePaginate = (newPage) => {
    if (
      newPage > 0 &&
      newPage <= Math.ceil(filteredRotations.length / rotationsPerPage)
    ) {
      setPage(newPage);
    }
  };

  return (
    <div className="relative overflow-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Rotation
            </th>
            <th scope="col" className="px-6 py-3">
              Type
            </th>
            <th scope="col" className="px-6 py-3">
              Site
            </th>
            <th scope="col" className="px-6 py-3">
              Student
            </th>
            <th scope="col" className="px-6 py-3">
              Start
            </th>
            <th scope="col" className="px-6 py-3">
              End
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRotations.map((rotation, i) => (
            <tr
              key={i}
              onClick={() =>
                navigate &&
                navigate(
                  `/app/university/1vln4o2CDpVwdGVEmLT6/${rotation.opps.location.id}/card/${rotation.id}`
                )
              }
              className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${
                navigate ? "cursor-pointer" : ""
              }`}
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {rotation.opps.name}
              </th>
              <td className="px-6 py-4">
                <span
                  className={`text-sm font-medium mr-2 px-2.5 py-0.5 rounded ${getTypeBadgeColor(
                    "Core"
                  )}`}
                >
                  Core
                </span>
              </td>
              <td className="px-6 py-4">{rotation.location.name}</td>
              <td className="px-6 py-4">{rotation.student.name}</td>
              <td className="px-6 py-4">
                {rotation.opps.startDate
                  ? typeof rotation.opps.startDate === "object"
                    ? new Date(
                        rotation.opps.startDate.seconds * 1000
                      ).toLocaleDateString("en-GB")
                    : new Date(rotation.opps.startDate).toLocaleDateString(
                        "en-GB"
                      )
                  : "N/A"}
              </td>
              <td className="px-6 py-4">
                {rotation.opps.endDate
                  ? typeof rotation.opps.endDate === "object"
                    ? new Date(
                        rotation.opps.endDate.seconds * 1000
                      ).toLocaleDateString("en-GB")
                    : new Date(rotation.opps.endDate).toLocaleDateString(
                        "en-GB"
                      )
                  : "N/A"}
              </td>
              <td className="px-6 py-4">
                <span
                  className={`text-sm font-medium mr-2 px-2.5 py-0.5 rounded ${getStatusBadgeColor(
                    rotation.currentStageOrder
                  )}`}
                >
                  {rotation?.currentStageName}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <nav
        className="flex items-center justify-between pt-4 flex-wrap"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Showing{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {indexOfFirstRotation + 1}-{indexOfLastRotation}
          </span>{" "}
          of{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {filteredRotations.length}
          </span>
        </span>
        <ul className="inline-flex -space-x-px text-sm h-8">
          <li>
            <button
              className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={() => handlePaginate(page - 1)}
              disabled={page === 1}
            >
              Previous
            </button>
          </li>
          {Array.from({
            length: Math.ceil(filteredRotations.length / rotationsPerPage),
          }).map((_, index) => (
            <li key={index}>
              <button
                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                  page === index + 1
                    ? "text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }`}
                onClick={() => handlePaginate(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={() => handlePaginate(page + 1)}
              disabled={
                page === Math.ceil(filteredRotations.length / rotationsPerPage)
              }
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default RotationsTable;
