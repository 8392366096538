import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCard,
  deleteCard,
  handleCardAccept,
  checkStageCompletion,
} from "../../services/firebaseService";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../configuration";
import FormSubmissionDetails from "../FormSubmissionDetails/FormSubmissionDetails";
import { format } from "date-fns";
import StageDetails from "../StageDetails/StageDetails";
import { UserContext } from "../../UserContext";

const Card = () => {
  const { permissions } = useContext(UserContext);
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmAccept, setConfirmAccept] = useState(false);
  const [canProgress, setCanProgress] = useState(false);
  const [isLastStage, setIsLastStage] = useState(false);

  const { cardId } = useParams();
  const navigate = useNavigate();

  const canPerformAction = (action) => {
    return permissions?.ALL || permissions?.[action];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cardData, stageCompleted] = await Promise.all([
          getCard(cardId),
          checkStageCompletion(cardId),
        ]);
        setCard(cardData);
        setCanProgress(stageCompleted);

        // Get lifecycle data to check stages
        const lifecycleRef = doc(db, "lifecycles", cardData.lifecycleId);
        const lifecycleDoc = await getDoc(lifecycleRef);
        const lifecycleData = lifecycleDoc.data();

        // Check if current stage is the last stage in the lifecycle
        const isLast =
          lifecycleData.stages[lifecycleData.stages.length - 1].name ===
          cardData.currentStageName;
        setIsLastStage(isLast);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [cardId]);

  const handleDelete = async () => {
    setConfirmDelete(false);
    await deleteCard(cardId);
    navigate(-1);
  };

  const handleAccept = async () => {
    try {
      setConfirmAccept(false);
      setLoading(true);
      await handleCardAccept(cardId);

      // Refresh the card data to show new stage
      const updatedCard = await getCard(cardId);
      setCard(updatedCard);

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
        <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
            Error
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            onClick={() => setError(null)}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (!card) return null;

  if (!canPerformAction("viewRotation")) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-50 dark:bg-red-900 p-4 rounded-lg">
          <p className="text-red-700 dark:text-red-200">
            You don't have permission to view this rotation.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6 flex items-center">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back
        </button>
      </div>
      {/* Form Submission Details */}
      <div className="mb-8">
        <FormSubmissionDetails rotationId={cardId} />
      </div>

      {/* Stage Details */}
      <div className="mb-8">
        <StageDetails
          rotationId={cardId}
          canCompleteTask={canPerformAction("completeRotationTasks")}
          canCompleteEvaluation={canPerformAction("completeEvaluation")}
        />
      </div>

      {/* Rotation Details */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
            Rotation Details
          </h2>

          {/* Opportunity Details */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
              Opportunity Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-semibold">Name:</span> {card.opps?.name}
                </p>
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-semibold">Location:</span>{" "}
                  {card.location?.name}
                </p>
              </div>
              <div>
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-semibold">Start Date:</span>{" "}
                  {card.opps?.startDate &&
                    format(new Date(card.opps.startDate.seconds * 1000), "PPP")}
                </p>
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-semibold">End Date:</span>{" "}
                  {card.opps?.endDate &&
                    format(new Date(card.opps.endDate.seconds * 1000), "PPP")}
                </p>
              </div>
            </div>
          </div>

          {/* Student Details */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
              Student Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-semibold">Name:</span>{" "}
                  {card.student?.name}
                </p>
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-semibold">Email:</span>{" "}
                  {card.student?.email}
                </p>
              </div>
            </div>
          </div>

          {/* Status */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
              Status Information
            </h3>
            <div>
              <p className="text-gray-600 dark:text-gray-400">
                <span className="font-semibold">Current Status:</span>{" "}
                <span
                  className={`px-2 py-1 rounded ${
                    card.status === 1
                      ? "bg-yellow-200 text-yellow-800"
                      : card.status === 2
                      ? "bg-green-200 text-green-800"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {card.status === 1
                    ? "Pending"
                    : card.status === 2
                    ? "Accepted"
                    : "Unknown"}
                </span>
              </p>
              {isLastStage && (
                <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900 rounded-lg">
                  <p className="text-blue-800 dark:text-blue-200">
                    This is the final stage of the rotation. All requirements
                    have been completed.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Action Buttons - Only show if not last stage and has permission */}
        {!isLastStage && canPerformAction("progressRotation") && (
          <div className="flex justify-end space-x-4">
            <button
              className={`px-6 py-2 rounded-lg text-white transition-colors ${
                canProgress
                  ? "bg-green-500 hover:bg-green-600"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onClick={() => canProgress && setConfirmAccept(true)}
              disabled={!canProgress}
              title={!canProgress ? "Complete all tasks before proceeding" : ""}
            >
              Accept
            </button>
            <button
              className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
              onClick={() => setConfirmDelete(true)}
            >
              Reject
            </button>
          </div>
        )}
      </div>

      {/* Confirmation Modals */}
      {confirmDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Confirm Rejection
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              Are you sure you want to reject this request?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
                onClick={() => setConfirmDelete(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDelete}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmAccept && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Confirm Acceptance
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              All tasks are completed. Are you sure you want to proceed to the
              next stage?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
                onClick={() => setConfirmAccept(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                onClick={handleAccept}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
