import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getEvaluationForCard,
  getQuestions,
  updateEvaluation,
  getRotation,
} from "../../services/firebaseService";

const SubmitEvaluation = () => {
  const { rotationId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [evaluation, setEvaluation] = useState(null);
  const [rotation, setRotation] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [rotationData, questionsData] = await Promise.all([
          getRotation(rotationId),
          getQuestions(),
        ]);

        setRotation(rotationData);
        setQuestions(questionsData);

        if (rotationData.currentStageEvaluationId) {
          const evaluationData = await getEvaluationForCard(
            rotationData.currentStageEvaluationId,
            rotationId
          );
          setEvaluation(evaluationData);

          if (evaluationData.status === "completed") {
            // navigate(`/rotations/${rotationId}`);
            return;
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rotationId, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(e.target);

      const categories = questions.map((category) => ({
        categoryName: category.category,
        questions: category.questions.map((question) => ({
          questionId: question.id,
          questionText: question.text,
          selectedOption: formData.get(`question_${question.id}`),
        })),
      }));

      const totalQuestions = categories.reduce(
        (total, category) => total + category.questions.length,
        0
      );
      const totalScore = categories.reduce(
        (score, category) =>
          score +
          category.questions.reduce((categoryScore, question) => {
            const value = question.selectedOption;
            return categoryScore + parseInt(value);
          }, 0),
        0
      );
      const evaluationScore = Math.round(
        (totalScore / (totalQuestions * 5)) * 100
      );

      await updateEvaluation(evaluation.id, {
        categories,
        overallFeedback: formData.get("overallFeedback"),
        evaluationScore,
        status: "completed",
        completedAt: new Date(),
      });

      navigate(`/rotations/${rotationId}`);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const renderCompletedEvaluation = () => {
    return (
      <div className="space-y-8">
        {evaluation.categories.map((category) => (
          <div
            key={category.categoryName}
            className="mb-8 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md"
          >
            <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
              {category.categoryName}
            </h3>

            {category.questions.map((question, qIndex) => (
              <div
                key={question.questionId}
                className="mb-6 border-b border-gray-300 dark:border-gray-600 pb-4 last:border-b-0"
              >
                <p className="mb-3 text-lg font-medium text-gray-800 dark:text-gray-200">
                  {`${qIndex + 1}. ${question.questionText}`}
                </p>

                <div className="mt-2 p-3 bg-white dark:bg-gray-600 rounded-md">
                  <p className="text-gray-700 dark:text-gray-300">
                    Selected Answer:{" "}
                    <span className="font-semibold">
                      {question.selectedOption}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ))}

        <div className="mb-6">
          <h3 className="block mb-2 font-semibold dark:text-white">
            Overall Feedback
          </h3>
          <div className="w-full p-4 bg-white dark:bg-gray-700 rounded-lg border dark:border-gray-600">
            <p className="text-gray-700 dark:text-gray-300">
              {evaluation.overallFeedback}
            </p>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="block mb-2 font-semibold dark:text-white">
            Evaluation Score
          </h3>
          <div className="w-full p-4 bg-white dark:bg-gray-700 rounded-lg border dark:border-gray-600">
            <p className="text-gray-700 dark:text-gray-300">
              {evaluation.evaluationScore}%
            </p>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={() => navigate(`/rotations/${rotationId}`)}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-white"
          >
            Back to Rotation
          </button>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      </div>
    );
  }

  if (!rotation || !evaluation) {
    return (
      <div className="container mx-auto p-4">
        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative">
          This evaluation is not available.
          <button
            onClick={() => navigate(`/rotations/${rotationId}`)}
            className="ml-4 underline hover:text-yellow-800"
          >
            Return to Rotation
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-6 dark:text-white">
          {evaluation.status === "completed"
            ? "Completed Evaluation"
            : "Submit Evaluation"}
        </h1>

        {/* Rotation Details */}
        <div className="mb-8 bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
          <h2 className="text-lg font-semibold mb-2 dark:text-white">
            Rotation Details
          </h2>
          <p className="dark:text-gray-300">
            Student: {rotation?.student?.name || "N/A"}
          </p>
          <p className="dark:text-gray-300">
            Location: {rotation?.location?.name || "N/A"}
          </p>
          <p className="dark:text-gray-300">
            Opportunity: {rotation?.opps?.name || "N/A"}
          </p>
        </div>

        {evaluation.status === "completed" ? (
          renderCompletedEvaluation()
        ) : (
          <form onSubmit={handleSubmit} className="space-y-8">
            {questions.map((category) => (
              <div
                key={category.category}
                className="mb-8 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md"
              >
                <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
                  {category.category}
                </h3>

                {category.questions.map((question, qIndex) => (
                  <div
                    key={question.id}
                    className="mb-6 border-b border-gray-300 dark:border-gray-600 pb-4 last:border-b-0"
                  >
                    <p className="mb-3 text-lg font-medium text-gray-800 dark:text-gray-200">
                      {`${qIndex + 1}. ${question.text}`}
                    </p>

                    <div className="grid grid-cols-1 gap-3">
                      {question.options.map((option, index) => (
                        <label
                          key={index}
                          className="flex items-center p-3 bg-white dark:bg-gray-600 rounded-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-500 transition-colors"
                        >
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            className="form-radio h-5 w-5 text-blue-600"
                            required
                          />
                          <span className="ml-3 text-gray-700 dark:text-gray-300">
                            {option}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}

            <div className="mb-6">
              <label className="block mb-2 font-semibold dark:text-white">
                Overall Feedback
              </label>
              <textarea
                name="overallFeedback"
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                rows="4"
                required
              ></textarea>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate(`/rotations/${rotationId}`)}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-white"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Submit Evaluation
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SubmitEvaluation;
