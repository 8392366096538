import { useState, useEffect, useContext } from "react";
import {
  getStageDetails,
  updateTaskStatus,
} from "../../services/firebaseService";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../UserContext";

const StageDetails = ({
  rotationId,
  canCompleteTask = false,
  canCompleteEvaluation = false,
}) => {
  const [stageData, setStageData] = useState({ rotation: null, stages: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [selectedTasks, setSelectedTasks] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [expandedStages, setExpandedStages] = useState({});
  const navigate = useNavigate();
  const { baseId, locationId } = useParams();
  const { permissions } = useContext(UserContext);

  const canPerformAction = (action) => {
    return permissions?.ALL || permissions?.[action];
  };

  useEffect(() => {
    const fetchStageDetails = async () => {
      try {
        const data = await getStageDetails(rotationId);
        setStageData(data);
        // Initialize selected tasks state
        const initialSelectedTasks = {};
        data.stages.forEach((stage) => {
          stage.tasks.forEach((task) => {
            initialSelectedTasks[task.id] = task.status === "completed";
          });
        });
        setSelectedTasks(initialSelectedTasks);

        // Auto-expand current stage
        const initialExpandedStages = {};
        data.stages.forEach((stage) => {
          initialExpandedStages[stage.name] = stage.isCurrentStage;
        });
        setExpandedStages(initialExpandedStages);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (rotationId) {
      fetchStageDetails();
    }
  }, [rotationId]);

  const handleFileChange = (taskId, questionIndex, e) => {
    const file = e.target.files[0];
    setFiles((prev) => ({
      ...prev,
      [`${taskId}-${questionIndex}`]: file,
    }));
  };

  const handleTaskSelect = (taskId) => {
    setSelectedTasks((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const selectedTaskIds = Object.entries(selectedTasks)
        .filter(([_, isSelected]) => isSelected)
        .map(([taskId]) => taskId);

      await updateTaskStatus(rotationId, selectedTaskIds, files);

      // Refresh stage details
      const updatedData = await getStageDetails(rotationId);
      setStageData(updatedData);
      setFiles({});
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const toggleStage = (stageName) => {
    setExpandedStages((prev) => ({
      ...prev,
      [stageName]: !prev[stageName],
    }));
  };

  const handleEvaluation = (evaluationId) => {
    if (!canCompleteEvaluation) return;
    navigate(`/university/${baseId}/${locationId}/evaluations/${rotationId}`);
  };

  const renderStageActions = (stage) => {
    if (!canCompleteTask) return null;

    const isFirstStage = stage.order === 1;
    const isEvaluationStage = stage.evaluation && stage.evaluationId;
    const hasIncompleteTasks = stage.tasks.some(
      (task) => task.status !== "completed"
    );

    if (!stage.isCurrentStage) return null;

    if (isEvaluationStage) {
      return (
        <div className="space-y-4">
          {/* Show evaluation status */}
          <div className="flex items-center space-x-2">
            <span
              className={`px-3 py-1 text-sm rounded-full ${
                stage.evaluationCompleted
                  ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                  : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100"
              }`}
            >
              {stage.evaluationCompleted
                ? "Evaluation Completed"
                : "Evaluation Pending"}
            </span>
          </div>

          {/* Show appropriate button based on evaluation status */}
          {!stage.evaluationCompleted ? (
            <button
              onClick={() => handleEvaluation(stage.evaluationId)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Complete Evaluation
            </button>
          ) : hasIncompleteTasks ? (
            <div className="space-y-2">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Evaluation completed. Please complete remaining tasks to
                proceed.
              </p>
              <button
                onClick={handleSubmit}
                disabled={
                  submitting || Object.values(selectedTasks).every((v) => !v)
                }
                className={`px-4 py-2 rounded-lg text-white ${
                  submitting || Object.values(selectedTasks).every((v) => !v)
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {submitting ? (
                  <span className="flex items-center space-x-2">
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white" />
                    <span>Submitting...</span>
                  </span>
                ) : (
                  "Complete Remaining Tasks"
                )}
              </button>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <button
        onClick={handleSubmit}
        disabled={submitting || Object.values(selectedTasks).every((v) => !v)}
        className={`px-4 py-2 rounded-lg text-white ${
          submitting || Object.values(selectedTasks).every((v) => !v)
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-blue-500 hover:bg-blue-600"
        }`}
      >
        {submitting ? (
          <span className="flex items-center space-x-2">
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white" />
            <span>Submitting...</span>
          </span>
        ) : (
          "Complete Stage"
        )}
      </button>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <p className="text-red-500 dark:text-red-400">Error: {error}</p>
      </div>
    );
  }

  if (!canPerformAction("viewRotation")) {
    return null;
  }

  const { rotation, stages } = stageData;

  if (!rotation || stages.length === 0) {
    return (
      <div className="p-4">
        <p className="dark:text-gray-300">No stage information available</p>
      </div>
    );
  }

  return (
    <div className="border rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="p-4">
        <h2 className="text-xl font-semibold dark:text-white mb-4">
          Rotation Stages Progress
        </h2>

        {stages
          .filter((stage) => stage.tasks.length > 0 || stage.isCurrentStage)
          .map((stage, stageIndex) => (
            <div
              key={stage.name}
              className={`mb-6 ${stage.isCompleted ? "opacity-75" : ""}`}
            >
              <div
                className="flex items-center justify-between mb-2 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 p-2 rounded-lg"
                onClick={() => toggleStage(stage.name)}
              >
                <div className="flex items-center space-x-3">
                  {stage.isCompleted ? (
                    <span className="text-green-500">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                  ) : stage.isCurrentStage ? (
                    <span className="text-blue-500">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span className="text-gray-400">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 15v2m0 0v2m0-2h2m-2 0H10"
                        />
                      </svg>
                    </span>
                  )}
                  <h3 className="text-lg font-semibold dark:text-white">
                    Stage {stageIndex + 1}: {stage.name}
                  </h3>
                </div>
                <div className="flex items-center space-x-3">
                  {stage.evaluation && (
                    <span
                      className={`px-3 py-1 text-sm rounded-full ${
                        stage.evaluationCompleted
                          ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                          : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100"
                      }`}
                    >
                      {stage.evaluationCompleted
                        ? "Evaluation ✓"
                        : "Evaluation Pending"}
                    </span>
                  )}
                  <svg
                    className={`w-5 h-5 transform transition-transform ${
                      expandedStages[stage.name] ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>

              {/* Collapsible content */}
              {expandedStages[stage.name] &&
                (stage.isCurrentStage || stage.isCompleted) && (
                  <div className="ml-9 space-y-3 mt-3">
                    {stage.tasks.map((task, index) => (
                      <div
                        key={task.id}
                        className="p-4 rounded-lg bg-gray-50 dark:bg-gray-700 space-y-2"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3">
                            {stage.isCurrentStage && canCompleteTask && (
                              <input
                                type="checkbox"
                                id={`task-${task.id}`}
                                checked={selectedTasks[task.id]}
                                onChange={() => handleTaskSelect(task.id)}
                                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                              />
                            )}
                            <h4 className="font-medium dark:text-white">
                              {index + 1}. {task.name}
                            </h4>
                          </div>
                          <span
                            className={`px-2 py-1 text-sm rounded-full ${
                              task.status === "completed"
                                ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                                : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100"
                            }`}
                          >
                            {task.status === "completed"
                              ? "Completed"
                              : "Pending"}
                          </span>
                        </div>

                        {stage.isCurrentStage &&
                          selectedTasks[task.id] &&
                          task.questions &&
                          task.questions.length > 0 && (
                            <div className="ml-6 mt-2 space-y-2">
                              {task.questions.map((question, qIndex) => (
                                <div key={qIndex} className="space-y-1">
                                  <div className="text-sm text-gray-600 dark:text-gray-300">
                                    • {question.text}
                                  </div>
                                  {question.requiresFile && (
                                    <div className="flex items-center space-x-2">
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          handleFileChange(task.id, qIndex, e)
                                        }
                                        className="text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0 file:text-sm file:font-semibold
                                    file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                      />
                                      {files[`${task.id}-${qIndex}`] && (
                                        <span className="text-xs text-green-500">
                                          File selected:{" "}
                                          {files[`${task.id}-${qIndex}`].name}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}

                    {/* Render stage-specific actions */}
                    {renderStageActions(stage)}
                  </div>
                )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default StageDetails;
