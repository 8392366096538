import { useEffect, useState } from "react";
import { getLocations } from "../../services/firebaseService";

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsData = await getLocations();
        setLocations(locationsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLocations();
  }, []);

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
        </div>
      )}
      {error && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Error
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => setError(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Locations</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {locations.map((location) => (
            <div
              key={location.id}
              className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex items-center justify-center"
            >
              <div className="flex flex-col items-center">
                {location.image && (
                  <img
                    src={location.image}
                    alt={`${location.name} image`}
                    className="h-32 object-cover rounded mb-2 mr-4"
                  />
                )}
                <h2 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                  {location.name}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Locations;
