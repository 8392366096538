import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocationContext } from "../../LocationContext";

const SidebarLocations = ({ setLocationId }) => {
  const { selectedLocation, setSelectedLocation, locations, loading } =
    useContext(LocationContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    navigate(`/university/${location.id}/rotations`);
    setIsOpen(false);
    setLocationId(location.id);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative mb-4">
      <span className="text-gray-500 dark:text-gray-400 mr-2">Location:</span>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedLocation && (
          <>
            <img
              src={selectedLocation.image}
              alt={`${selectedLocation.name} image`}
              className="h-10 w-10 object-cover rounded mr-2"
            />
            <span className="text-gray-900 dark:text-white">
              {selectedLocation.name}
            </span>
          </>
        )}
      </div>

      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute mt-2 w-64 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg z-10"
        >
          <div className="max-h-60 overflow-y-auto">
            {locations.map((location) => (
              <div
                key={location.id}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                onClick={() => handleLocationClick(location)}
              >
                {location.image && (
                  <img
                    src={location.image}
                    alt={`${location.name} image`}
                    className="h-10 w-10 object-cover rounded mr-2"
                  />
                )}
                <span className="text-gray-900 dark:text-white">
                  {location.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarLocations;
