import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "./UserContext";

const PrivateRoute = ({ requiredPermission, navigatePath }) => {
  const { user, permissions } = React.useContext(UserContext);

  if (!user) {
    return null; // Or a loading spinner
  }

  // Check if the user has the required permission or ALL permission
  if (permissions) {
    return permissions?.ALL || permissions?.[requiredPermission] ? (
      <Outlet />
    ) : (
      <Navigate to={navigatePath} replace />
    );
  }
};

export default PrivateRoute;
