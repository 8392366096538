import React, { useState } from "react";
import { createItem } from "../services/firebaseService";

const AlterDB = () => {
  const [collectionName, setCollectionName] = useState("");
  const [jsonInput, setJsonInput] = useState("");
  const [message, setMessage] = useState("");

  const handleCollectionNameChange = (e) => {
    setCollectionName(e.target.value);
  };

  const handleJsonInputChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jsonData = JSON.parse(jsonInput);
      if (Array.isArray(jsonData)) {
        for (const item of jsonData) {
          await createItem(collectionName, item);
        }
        setMessage("Items successfully added to the collection!");
      } else {
        setMessage("Error: JSON input should be an array of objects.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Alter Firebase Database</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="collectionName"
          >
            Collection Name
          </label>
          <input
            type="text"
            id="collectionName"
            value={collectionName}
            onChange={handleCollectionNameChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="jsonInput"
          >
            JSON Input
          </label>
          <textarea
            id="jsonInput"
            value={jsonInput}
            onChange={handleJsonInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="10"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
      {message && <p className="text-red-500">{message}</p>}
    </div>
  );
};

export default AlterDB;
