import { useState, useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isToday,
  startOfWeek,
  endOfWeek,
  addDays,
} from "date-fns";
import "./Calendar.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function generateDays(currentMonth, events = []) {
  const start = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 0 });
  const end = endOfWeek(addMonths(startOfMonth(currentMonth), 1), {
    weekStartsOn: 0,
  });
  const days = eachDayOfInterval({ start, end }).map((date) => ({
    date: format(date, "yyyy-MM-dd"),
    isCurrentMonth: isSameMonth(date, currentMonth),
    isToday: isToday(date),
    events: [],
  }));

  // Add events to the days
  let orders = {};
  let order = 0;
  events.forEach((event, eventIndex) => {
    const eventStart = new Date(event.startDate);
    const eventEnd = new Date(event.endDate);
    eachDayOfInterval({ start: eventStart, end: eventEnd }).forEach((date) => {
      const day = days.find((d) => d.date === format(date, "yyyy-MM-dd"));
      if (day) {
        if (!orders[event.id]) {
          orders[event.id] = order;
          order++;
        }
        day.events.unshift({
          ...event,
          order: orders[event.id],
          isStart:
            format(date, "yyyy-MM-dd") === format(eventStart, "yyyy-MM-dd"),
          isEnd: format(date, "yyyy-MM-dd") === format(eventEnd, "yyyy-MM-dd"),
        });
      }
    });
  });

  if (days.length === 35) {
    const additionalDays = eachDayOfInterval({
      start: addDays(end, 1),
      end: addDays(end, 7),
    }).map((date) => ({
      date: format(date, "yyyy-MM-dd"),
      isCurrentMonth: isSameMonth(date, currentMonth),
      isToday: isToday(date),
      events: [],
    }));
    return [...days, ...additionalDays];
  }

  return days;
}

export default function Calendar({ events = [] }) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [days, setDays] = useState(generateDays(currentMonth, events));
  const [selectedDay, setSelectedDay] = useState(
    days.find((day) => day.isSelected)
  );

  useEffect(() => {
    const updatedDays = generateDays(currentMonth, events);
    setDays(updatedDays);
  }, [currentMonth]);

  useEffect(() => {
    const updatedDays = generateDays(currentMonth, events);
    setDays(updatedDays);
  }, [events]);

  const handlePrevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));
  const handleToday = () => setCurrentMonth(new Date());

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none dark:border-gray-700">
        <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
          <time dateTime={format(currentMonth, "yyyy-MM")}>
            {format(currentMonth, "MMMM yyyy")}
          </time>
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch dark:bg-gray-800">
            <button
              type="button"
              onClick={handlePrevMonth}
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:text-gray-200 dark:md:hover:bg-gray-700"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={handleToday}
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block dark:border-gray-600 dark:text-gray-100 dark:hover:bg-gray-700"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden dark:bg-gray-600" />
            <button
              type="button"
              onClick={handleNextMonth}
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:text-gray-200 dark:md:hover:bg-gray-700"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <MenuButton
                type="button"
                className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-100 dark:ring-gray-600 dark:hover:bg-gray-700"
              >
                Month view
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400 dark:text-gray-300"
                  aria-hidden="true"
                />
              </MenuButton>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:ring-gray-600 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                    >
                      Day view
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                    >
                      Week view
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                    >
                      Month view
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                    >
                      Year view
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          <Menu as="div" className="relative ml-6 md:hidden">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <MenuItems
              transition
              className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:bg-gray-800 dark:ring-gray-600 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                  >
                    Create event
                  </a>
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                  >
                    Go to today
                  </a>
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                  >
                    Day view
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                  >
                    Week view
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                  >
                    Month view
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 dark:text-gray-300 dark:data-[focus]:bg-gray-700 dark:data-[focus]:text-gray-100"
                  >
                    Year view
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </header>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col dark:ring-white dark:ring-opacity-10">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100">
          <div className="bg-white py-2 dark:bg-gray-800">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-800">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-800">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-800">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-800">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-800">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-800">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto dark:bg-gray-800 dark:text-gray-100">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {days.map((day, index) => {
              return (
                <div
                  key={day.date}
                  className={classNames(
                    day.isCurrentMonth
                      ? selectedDay === day
                        ? "bg-gray-200 dark:bg-gray-700"
                        : "bg-white dark:bg-gray-900"
                      : "bg-gray-50 text-gray-500 dark:bg-gray-700 dark:text-gray-400",
                    "relative py-2"
                  )}
                  onClick={() => {
                    setSelectedDay(day);
                  }}
                >
                  <time
                    style={{ marginLeft: "10px" }}
                    dateTime={day.date}
                    className={
                      day.isToday
                        ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                        : undefined
                    }
                  >
                    {day.date.split("-").pop().replace(/^0/, "")}
                  </time>
                  {day.events.length > 0 && (
                    <ol className="mt-2">
                      {day.events.map((event, eventIndex) => {
                        return (
                          <>
                            {/* {event.order > eventIndex && (
                              <li
                                key={`placeholder-${eventIndex}`}
                                className="event-placeholder"
                              ></li>
                            )} */}
                            <li
                              key={event.id}
                              className={classNames(
                                "event-span",
                                event.isStart && "start",
                                event.isEnd && "end"
                              )}
                              style={{
                                backgroundColor: event.color,
                              }}
                            >
                              {(event.isStart ||
                                event.isEnd ||
                                index === 0) && (
                                <a href={event.href} className="group flex">
                                  <p className="flex-auto truncate font-medium text-white group-hover:text-indigo-600">
                                    {event.isStart && "Start "}
                                    {event.isEnd && "End "}
                                    {event.name}
                                  </p>
                                  <time
                                    dateTime={event.datetime}
                                    className="ml-3 hidden flex-none text-white group-hover:text-indigo-600 xl:block"
                                  >
                                    {event.time}
                                  </time>
                                </a>
                              )}
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  )}
                </div>
              );
            })}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                className={classNames(
                  day.isCurrentMonth
                    ? selectedDay === day
                      ? "bg-gray-200 dark:bg-gray-700"
                      : "bg-white dark:bg-gray-900"
                    : "bg-gray-50 dark:bg-gray-700",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected && day.isToday && "text-indigo-600",
                  !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-900 dark:text-gray-100",
                  !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-500 dark:text-gray-400",
                  "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10 dark:hover:bg-gray-700"
                )}
                onClick={() => {
                  setSelectedDay(day);
                }}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    day.isSelected &&
                      "flex h-6 w-6 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-indigo-600",
                    day.isSelected && !day.isToday && "bg-gray-900",
                    "ml-auto"
                  )}
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
                <span className="sr-only">{day.events.length} events</span>
                {day.events.length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {day.events.map((event) => (
                      <span
                        key={event.id}
                        className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400 dark:bg-gray-500"
                      />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {selectedDay?.events.length > 0 && (
        <div className="px-4 py-10 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5 dark:divide-gray-700 dark:bg-gray-800 dark:ring-white dark:ring-opacity-10">
            {selectedDay.events.map((event) => (
              <li
                key={event.id}
                className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50 dark:focus-within:bg-gray-700 dark:hover:bg-gray-700"
              >
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900 dark:text-gray-100">
                    {event.name}
                  </p>
                  <time
                    dateTime={event.datetime}
                    className="mt-2 flex items-center text-gray-700 dark:text-gray-300"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400 dark:text-gray-300"
                      aria-hidden="true"
                    />
                    {event.time}
                  </time>
                </div>
                <a
                  href={event.href}
                  className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100 dark:bg-gray-800 dark:text-gray-100 dark:ring-gray-600 dark:hover:ring-gray-500"
                >
                  Edit<span className="sr-only">, {event.name}</span>
                </a>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
