import { useState, useEffect } from "react";
import {
  getFormById,
  submitFormWithFiles,
} from "../../services/firebaseService";

const RequestForm = ({
  formId,
  opportunityId,
  studentId,
  onSubmit,
  onCancel,
}) => {
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [files, setFiles] = useState({});

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const formData = await getFormById(formId);
        if (formData) {
          setForm(formData);
          // Initialize answers object
          const initialAnswers = {};
          formData.questions.forEach((_, index) => {
            initialAnswers[index] = "";
          });
          setAnswers(initialAnswers);
        }
      } catch (error) {
        console.error("Error fetching form:", error);
      } finally {
        setLoading(false);
      }
    };

    if (formId) {
      fetchForm();
    }
  }, [formId]);

  const handleInputChange = (index, value) => {
    setAnswers((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleFileChange = (index, file) => {
    setFiles((prev) => ({
      ...prev,
      [index]: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { rotationId, submissionId } = await submitFormWithFiles({
        opportunityId,
        studentId,
        formId,
        answers,
        files,
        questions: form.questions,
      });
      onSubmit(submissionId, rotationId);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-gray-900 dark:text-gray-100">Loading form...</div>
    );
  }

  if (!form) {
    return (
      <div className="text-gray-900 dark:text-gray-100">Form not found</div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
        {form.name}
      </h2>

      {form.questions.map((question, index) => (
        <div key={index} className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {question.text}
          </label>

          {question.requiresFile ? (
            <input
              type="file"
              onChange={(e) => handleFileChange(index, e.target.files[0])}
              className="block w-full text-sm text-gray-500 dark:text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 dark:file:bg-blue-900 file:text-blue-700 dark:file:text-blue-300 hover:file:bg-blue-100 dark:hover:file:bg-blue-800"
            />
          ) : (
            <input
              type="text"
              value={answers[index] || ""}
              onChange={(e) => handleInputChange(index, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
          )}
        </div>
      ))}

      <div className="flex justify-end space-x-2 mt-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 dark:bg-blue-500 border border-transparent rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default RequestForm;
