// src/UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth } from "./configuration"; // Ensure this path is correct

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Fetch user data from Firestore
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser({
            id: currentUser.uid,
            email: currentUser.email,
            authData: currentUser,
            ...userData,
          });

          // Fetch permissions using the reference in the user document
          if (userData.role) {
            // console.log("userData role", userData.role);
            const permissionsDoc = await getDoc(userData.role);
            // console.log(permissionsDoc);
            if (permissionsDoc.exists()) {
              //   console.log("permissionsDoc", permissionsDoc.data());
              setPermissions({
                id: permissionsDoc.id,
                ...permissionsDoc.data(),
              });
            } else {
              //   console.log("No permissions data found in Firestore");
              setPermissions(null);
            }
          }
        } else {
          //   console.log("No user data found in Firestore");
          setUser(currentUser);
          setPermissions(null);
        }
      } else {
        setUser(null);
        setPermissions(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  return (
    <UserContext.Provider value={{ user, permissions }}>
      {children}
    </UserContext.Provider>
  );
};
