import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = ({ initialZoom = 13, markers = [], mapStyle = {} }) => {
  const [center, setCenter] = useState([21.5433, 39.1728]); // Default to Jeddah
  const [zoom, setZoom] = useState(initialZoom);
  const mapRef = useRef();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter([position.coords.latitude, position.coords.longitude]);
          // console.log("User location:", position.coords);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(center, zoom);
    }
  }, [center, zoom]);

  // Add a new marker at the specified coordinates
  const newMarker = {
    position: [21.4933, 39.2471],
    popupContent: (
      <h1 className="text-2xl font-bold">New marker at 21.4933, 39.2471</h1>
    ),
  };

  const defaultMapStyle = { height: "90vh", width: "100%" };
  const combinedMapStyle = { ...defaultMapStyle, ...mapStyle };

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={combinedMapStyle}
      whenCreated={(mapInstance) => {
        mapRef.current = mapInstance;
        mapInstance.on("moveend", () => {
          setCenter(mapInstance.getCenter());
          setZoom(mapInstance.getZoom());
        });
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
          <Popup>{marker.popupContent}</Popup>
        </Marker>
      ))}
      <Marker position={newMarker.position}>
        <Popup>{newMarker.popupContent}</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
